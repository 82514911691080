import { EventType } from '@wix/editor-platform-sdk-types';

import { openAddStatusWidgetPanel } from './openAddStatusWidgetPanel';
import { EditorScriptContext } from './types';
import { markStatusWidgetPanelAsShown, wasStatusWidgetPanelShown } from './userPreferences';

export const registerPageNavigationEvent = async (context: EditorScriptContext) => {
  const { editorSDK, flowAPI } = context;

  await editorSDK.addEventListener(EventType.focusedPageChanged, async (event) => {
    const { pageRef } = event.detail;
    const { appDefinitionId } = await editorSDK.pages.getPageData('', { pageRef });

    if (appDefinitionId === flowAPI.environment.appDefinitionId) {
      const wasShown = await wasStatusWidgetPanelShown(editorSDK);
      if (wasShown) {
        return;
      }

      await markStatusWidgetPanelAsShown(editorSDK);
      await openAddStatusWidgetPanel(context);
    }
  });
};
