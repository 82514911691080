import { getLoyaltyProgram } from '@wix/ambassador-loyalty-v1-program/http';
import { MA_APP_IDS, withMembersArea } from '@wix/members-area-integration-kit';
import { EditorReadyFn, FlowAPI, FlowEditorSDK, GetAppManifestFn } from '@wix/yoshi-flow-editor';

import loyaltyPageComponent from './components/LoyaltyPage/.component.json';
import statusWidget from './components/LoyaltyStatus/.component.json';
import { Experiment } from './constants';
import {
  addStatusWidget,
  addStatusWidgetToStage,
  configureLoyaltyPageWidget,
  configureMyBusinessActions,
  configureStatusWidget,
  EditorPrivateApi,
  EditorPublicApi,
  EditorScriptContext,
  markStatusWidgetPanelAsShown,
  openAddStatusWidgetPanel,
  registerPageNavigationEvent,
} from './editor';

let _flowAPI: FlowAPI;

const _editorReady: EditorReadyFn = async (editorSDK, _appDefinitionId, { firstInstall, initialAppData }, flowAPI) => {
  const { httpClient } = flowAPI;
  const { applicationId } = initialAppData;

  _flowAPI = flowAPI;
  const isStatusWidgetEnabled = flowAPI.experiments.enabled(Experiment.StatusWidget);

  if (firstInstall) {
    const pages = await editorSDK.document.pages.data.getAll('');
    const page = pages.find(({ tpaApplicationId }) => tpaApplicationId === applicationId);
    const pageRef = { id: page?.id!, type: 'DESKTOP' } as const;

    // Navigate to newly added page
    await editorSDK.pages.navigateTo('', { pageRef });

    // Rename page to match Loyalty Program name
    try {
      const loyaltyProgramRequest = await httpClient.request(getLoyaltyProgram({}));
      const loyaltyProgram = loyaltyProgramRequest.data.loyaltyProgram;

      if (loyaltyProgram?.name) {
        await editorSDK.document.pages.rename('', {
          title: loyaltyProgram.name,
          pageRef,
        });
      }

      if (isStatusWidgetEnabled) {
        await addStatusWidgetToStage({ flowAPI, editorSDK });
        await markStatusWidgetPanelAsShown(editorSDK);
      }
    } catch (e) {
      flowAPI.reportError(e as Error);
    }
  } else {
    if (isStatusWidgetEnabled) {
      await registerPageNavigationEvent({ editorSDK, flowAPI });
    }
  }
};

const _getAppManifest: GetAppManifestFn = ({ appManifestBuilder }, editorSDK, _contextParams, flowAPI) => {
  const context: EditorScriptContext = { editorSDK, flowAPI };

  appManifestBuilder.configureWidget(loyaltyPageComponent.id, (widgetBuilder) => {
    configureLoyaltyPageWidget(context, widgetBuilder);
  });

  appManifestBuilder.configureWidget(statusWidget.id, (widgetBuilder) => {
    configureStatusWidget(context, widgetBuilder);
  });

  appManifestBuilder.configureManagementActions((managementActionsBuilder) => {
    configureMyBusinessActions(context, managementActionsBuilder);
  });

  return appManifestBuilder.build();
};

const _exports = (editorSDK: FlowEditorSDK) => ({
  public: {
    openAddStatusWidgetPanel: async () => openAddStatusWidgetPanel({ flowAPI: _flowAPI, editorSDK }),
  } satisfies EditorPublicApi,
  private: {
    addStatusWidget: async () => addStatusWidget({ flowAPI: _flowAPI, editorSDK }),
  } satisfies EditorPrivateApi,
});

export const { editorReady, getAppManifest, handleAction, onEvent, exports } = withMembersArea(
  {
    editorReady: _editorReady,
    getAppManifest: _getAppManifest,
    handleAction: async () => {},
    exports: _exports,
  },
  {
    membersAreaApps: [MA_APP_IDS.MY_REWARDS],
  },
);
