import { WidgetBuilder } from '@wix/yoshi-flow-editor';

import { HelpId } from '../../constants';
import { EditorScriptContext } from '../types';

export const configureStatusWidget = ({ flowAPI }: EditorScriptContext, widgetBuilder: WidgetBuilder) => {
  const { t } = flowAPI.translations;

  widgetBuilder.set({ displayName: t('status-widget.display-name') });

  widgetBuilder.gfpp().set('help', {
    id: HelpId.StatusWidget,
  });
};
