import { getPanelUrl } from '@wix/yoshi-flow-editor/utils';

import { AddStatusWidgetPanelData, EditorScriptContext } from './types';

export async function openAddStatusWidgetPanel({ flowAPI, editorSDK }: EditorScriptContext): Promise<void> {
  const { t } = flowAPI.translations;
  const { isClassicEditor } = flowAPI.environment;

  const initialData: AddStatusWidgetPanelData = { isClassicEditor };
  const height = isClassicEditor ? 487 : 402;

  await editorSDK.editor.openModalPanel('', {
    url: getPanelUrl('LoyaltyStatus', 'AddWidgetPanel'),
    type: editorSDK.editor.PanelType.Settings,
    initialData,
    width: 720,
    height,
    title: t('status-widget.add-panel.title'),
  });
}
